<div class="fixed-button active">
  <a
    href="https://codedthemes.com/item/gradient-able-angular-admin-template/?utm_source=free_demo&utm_medium=codedthemes&utm_campaign=button_download_premium"
    target="_blank"
    class="btn btn-md btn-primary has-ripple"
  >
    <i aria-hidden="true" class="fa fa-shopping-cart"></i>
    Upgrade To Pro
  </a>
</div>
