@if(item()?.title; as title) {
  <li class="nav-item pcoded-menu-caption">
    <label>{{ title }}</label>
  </li>
}
@for (item of item()?.children; track item) {
  @if (item.type === 'group') {
    <app-nav-group [item]="item"></app-nav-group>
  }
  @if (item.type === 'collapse') {
    <app-nav-collapse [item]="item"></app-nav-collapse>
  }
  @if (item.type === 'item') {
    <app-nav-item [item]="item"></app-nav-item>
  }
}
