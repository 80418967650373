@if (this.navigationList) {
  @for (breadcrumb of navigationList; track breadcrumb; let last = $last) {
    @if (last && breadcrumb.breadcrumbs !== false) {
      <div class="row align-items-center">
        <div class="col-md-12" class="breadcrumb-wrapper">
          <div class="page-header-title">
            @for (breadcrumb of navigationList; track breadcrumb; let last = $last) {
              @if (last) {
                <h5 class="m-b-10">{{ breadcrumb.title }}</h5>
              }
            }
          </div>
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard/analytics/']" title="Home"><i class="feather icon-home"></i></a>
            </li>
            @for (breadcrumb of navigationList; track breadcrumb) {
              @if (breadcrumb.url !== false) {
                <li class="breadcrumb-item">
                  <a [routerLink]="breadcrumb.url">{{ breadcrumb.title }}</a>
                </li>
              }
              @if (breadcrumb.url === false && breadcrumb.type !== 'group') {
                <li class="breadcrumb-item">
                  <a href="javascript:">{{ breadcrumb.title }}</a>
                </li>
              }
            }
          </ul>
        </div>
      </div>
    }
  }
}
