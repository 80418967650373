import { enableProdMode, importProvidersFrom } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAuth0 } from '@auth0/auth0-angular';

import { environment } from './environments/environment';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app/app.component';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule), provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAuth0({
      domain: 'dev-o8m2von71wzz7ti0.us.auth0.com',//TODO:
      clientId: 'vmg2p8ypruk0dRNxTHYJyWobh7B1HQt2',//TODO:
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    }),
  ]
}).catch((err) => console.error(err));
