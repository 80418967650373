<div class="card" [ngClass]="cardClass" [@cardRemove]="cardRemove" #toAnimate>
  @if (!hidHeader) {
    <div class="card-header" [ngClass]="headerClass()">
      @if (!customHeader) {
        <h5>{{ cardTitle }}</h5>
      }
      @if (!customDate) {
        <span class="d-block">{{ CardDate() }}</span>
      }
      @if (this.options && !customHeader) {
        <div class="card-header-right">
          <div class="btn-group card-option dropdown" ngbDropdown>
            <button type="button" class="btn dropdown-toggle btn-icon" ngbDropdownToggle>
              <i class="feather icon-more-horizontal"></i>
            </button>
            <ul class="list-unstyled card-option dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <li class="dropdown-item full-card" (click)="fullCardToggle(toAnimate, '', true)">
                <a href="javascript:">
                  <span>
                    <i class="feather" [ngClass]="fullIcon"></i>
                    {{ this.cardClass === 'full-card' ? 'Restore' : 'Maximize' }}
                  </span>
                </a>
              </li>
              <li class="dropdown-item minimize-card" (click)="collapsedCardToggle()">
                <a href="javascript:">
                  <span>
                    <i class="feather" [ngClass]="collapsedIcon"></i>
                    {{ this.collapsedCard === 'collapsed' ? 'Expand' : 'Collapse' }}
                  </span>
                  <span style="display: none"><i class="feather icon-plus"></i></span>
                </a>
              </li>
              <li class="dropdown-item reload-card" (click)="cardRefresh()">
                <a href="javascript:">
                  <i class="feather icon-refresh-cw"></i>
                  Reload
                </a>
              </li>
              <li class="dropdown-item close-card" (click)="cardRemoveAction()">
                <a href="javascript:">
                  <i class="feather icon-trash"></i>
                  Remove
                </a>
              </li>
            </ul>
          </div>
        </div>
      }
      <div></div>
      @if (customHeader) {
        <ng-content select=".app-card-header"></ng-content>
      }
      @if (customDate) {
        <ng-content select=".app-card-subheader"></ng-content>
      }
    </div>
  }

  <div [@collapsedCard]="collapsedCard">
    <div class="card-body" [ngClass]="blockClass()">
      <ng-content></ng-content>
    </div>
  </div>
  @if (isCardFooter) {
    <div class="card-footer" [ngClass]="footerClass()">
      <ng-content select=".app-card-footer"></ng-content>
    </div>
  }
  @if (loadCard) {
    <div class="card-loader">
      <i class="pct-loader1 anim-rotate"></i>
    </div>
  }
</div>
