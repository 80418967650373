<ng-scrollbar (clickOutside)="navMob()" style="height: calc(100vh - 56px)">
  <div class="navbar-content">
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      @for (item of navigations; track item) {
        @if (item.type === 'group') {
          <app-nav-group [item]="item"></app-nav-group>
        }
        @if (item.type === 'collapse') {
          <app-nav-collapse [item]="item"></app-nav-collapse>
        }
        @if (item.type === 'item') {
          <app-nav-item [item]="item"></app-nav-item>
        }
      }
    </ul>
    <div class="card text-center">
      <div class="card-body">
        <img src="assets/images/sidebar.png" alt="images" class="img-fluid mb-2" />
        <h5>Gradient Pro</h5>
        <p>Checkout Gradient Premium Features</p>
        <a
          href="https://codedthemes.com/item/gradient-able-angular-admin-template/?utm_source=free_demo&utm_medium=codedthemes&utm_campaign=button_download_premium"
          target="_blank"
          class="btn text-white btn-primary"
        >
          Download Premium
        </a>
      </div>
    </div>
    <div class="version">
      <label disabled class="pe-auto">v{{ currentApplicationVersion }}</label>
    </div>
  </div>
</ng-scrollbar>
