<app-navigation
  class="pcoded-navbar menupos-fixed menu-light"
  [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'mob-open': navCollapsedMob
  }"
  (NavCollapsedMob)="navMobClick()"
></app-navigation>
<app-nav-bar
  class="navbar pcoded-header navbar-expand-lg navbar-light header-blue headerPos-fixed"
  (NavCollapse)="this.navCollapsed = !this.navCollapsed"
  (NavCollapsedMob)="navMobClick()"
></app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <div class="main-body">
          <div class="page-wrapper">
            <div class="page-header">
              <div class="page-block">
                <app-breadcrumb></app-breadcrumb>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pc-menu-overlay" (click)="closeMenu()" (keydown)="handleKeyDown($event)" tabindex="0"></div>
</div>
<app-configuration></app-configuration>
